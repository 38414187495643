@import '../../../scss/variables';

.Setting {
    width: 600px;
    height: 420px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $bg-light-2;

    .Title h3{
        color: $text-light-5;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.005em;
        border-bottom: 1px solid $bd-light-3;
        padding: 12px 24px;
    }

    .SettingForm {
        padding: 16px 24px 24px;
        .InputWrapper {
            padding: 0.25rem 0;
            input {
                //padding: 0.35rem 0.75rem;
                padding: 6px 50px 6px 16px;
                background: $bg-light-3;
                border-radius: 0.25rem;
                width: 100%;
                border-radius: 4px;
                border: 1px solid $bd-light-2;
                color: $text-light-0;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.005em;
                height: 32px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .JwtInput {
                position: relative;
                button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0.35rem 0.75rem;
                    background: $secondary-light-0;
                    border-radius: 0.25rem;
                    height: 32px;
                    color: $text-light-7;
                }
            }
        }
        .SubmitWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;

            button {
                margin: auto;
                font-weight: bold;
                background-color: $secondary-light-0;
                color: $text-light-7;
                padding: 0.5rem 0.75rem;
                border-radius: 0.25rem;
            }
        }
    }
}
