@import '../../../scss/variables';

.NewBooking {
    height: 100%;
    .Title {
        color: $app-1;
        text-align: center;
        font-size: x-large;
        font-weight: bold;
        background: $bg-dark-0;
        height: 2rem;
    }
    .Body {
        display: flex;
        flex-direction: row;
        //height: calc(100% - 3rem);
        height: 100%;

        @import 'BookingInfo';
        @import 'MapPicker';
    }
}
