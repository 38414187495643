@import '../../../scss/variables';

.menu-actions {
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;

    .item-action img{
        width: 12px;
        height: 12px;
    }

    .item-action {
        display: flex;
        align-items: center;
        //border: 1px solid red;
        cursor: pointer;
    }

    .item-action span {
        color: #000;
        font-size: 13px;
        font-weight: 400;
        font-family: 'Roboto';
        margin-left: .5rem;
    }

    .divide {
        height: 1px;
        background-color: $bd-light-3;
    }
}


.menu-actions.hide {
    display: none;
}