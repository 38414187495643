@import '../../../scss/variables';

.LoginContainer {
    @apply flex justify-center items-center absolute h-full w-full;

    .FormLogin {
        background-color: $bg-dark-1;
        @apply px-4 py-6 rounded-sm flex flex-col gap-3 w-72;

        .LoginInput {
            background-color: $bg-dark-2;
        }

        .LoginButton {
            background-color: $app-1;
            &:hover,
            &:focus {
                box-shadow: 0px 0px 4px 1px $app-2;
            }
            @apply opacity-80 p-1 mt-2 font-bold text-base;
        }
    }
}
