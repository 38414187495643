@import '../../../scss/variables';

.BookingsContainer {
    @import 'Bookings';

    background: $bg-light-1;
    // background: white;

    position: absolute;
    width: 100%;

    //height: 100%;
    height: 50%;

    left: 0;
    bottom: 0;
    padding: 0px !important;
    padding-left: 1px !important;
    padding-top: 1px !important;

    z-index: 1;

    //border-left: 1px solid $app-1;
}

.BookingsContainerFullHeight {
    @import 'Bookings';

    background: $bg-light-1;

    // background: white;

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    padding: 2px !important;
    z-index: 1;
}

.BookingsContainerHidden {
    @import 'Bookings';
    height: 0;
}

.MuiPaper-root {
    max-height: none !important;
}
