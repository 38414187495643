@import '../../../scss/variables';
$boder-style: 1px solid;
$boder-left-style: 0.25rem solid;

.TripsWrapper {
    height: 100%;
    padding: 1rem 0;

    .Trip {
        padding: 0.25rem 0;

        .TripDetails {
            width: 100%;
            padding: 0.25rem;
            padding-left: 1rem;
            padding-right: 0.5rem;

            //border: $boder-style transparent;
            //border-left: $boder-left-style $bg-dark-1;
            border-bottom: 1px solid $bd-light-3;
            //background: $bg-dark-0;
            background: $bg-light-2;
            display: flex;
            color: $text-light-0;

            .InfoWrapper {
                width: 100%;
                display: flex;
                .Info {
                    padding: 0.125rem;
                    display: flex;

                    .InfoKey {
                        width: 6rem;
                        color: $text-2;
                    }

                    .InfoValue {
                        width: calc(100% - 6rem);
                        overflow-wrap: break-word;
                    }
                }
            }

            .OpenAction {
                color: $app-1;
                display: grid;
                align-content: center;
            }
        }

        .TripStatus0 {
            border-left: $boder-left-style $trip-status-0;

            &:hover {
                border: $boder-style $trip-status-0;
                border-left: $boder-left-style $trip-status-0;
            }
        }
        .TripStatus1 {
            border-left: $boder-left-style $trip-status-1;

            &:hover {
                border: $boder-style $trip-status-1;
                border-left: $boder-left-style $trip-status-1;
            }
        }
        .TripStatus2 {
            border-left: $boder-left-style $trip-status-2;

            &:hover {
                border: $boder-style $trip-status-2;
                border-left: $boder-left-style $trip-status-2;
            }
        }
        .TripStatus3 {
            border-left: $boder-left-style $trip-status-3;

            &:hover {
                border: $boder-style $trip-status-3;
                border-left: $boder-left-style $trip-status-3;
            }
        }
        .TripStatus4 {
            border-left: $boder-left-style $trip-status-4;

            &:hover {
                border: $boder-style $trip-status-4;
                border-left: $boder-left-style $trip-status-4;
            }
        }
        .TripStatus5 {
            border-left: $boder-left-style $trip-status-5;

            &:hover {
                border: $boder-style $trip-status-5;
                border-left: $boder-left-style $trip-status-5;
            }
        }
        .TripStatus6 {
            border-left: $boder-left-style $trip-status-6;

            &:hover {
                border: $boder-style $trip-status-6;
                border-left: $boder-left-style $trip-status-6;
            }
        }
        .TripStatus7 {
            border-left: $boder-left-style $trip-status-7;

            &:hover {
                border: $boder-style $trip-status-7;
                border-left: $boder-left-style $trip-status-7;
            }
        }
    }
}
