.checkbox-reason {
    display: flex;
    height: 44px;
    padding: 12px 0px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-right: .5rem;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Roboto';
        line-height: 20px;
        letter-spacing: -0.17px;
    }
}