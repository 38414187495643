@import '../../../scss/variables';

$bottom-height: 120px;

.BookingInfo {
    width: 35%;
    min-width: 400px;
    height: 100%;
    padding: 24px 32px;
    box-shadow: inset 1px 0px 5px 0px rgb(0 0 0 / .1);
    border-right: 1px solid rgba(0, 0, 0, .05);
    position: relative;
    min-height: 420px;
    overflow: hidden;

    @import 'AddressField';
    @import 'PickupTimeField';

    .FormBooking {
        // border: 1px solid green;
        .Heading {
            color: $text-light-5;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.005em;
            text-transform: uppercase;
        }

        .customer-info {
            margin-top: 1.6rem;
        }

        .customer-info > div {
            flex: 1 !important;
        }

        .customer-info > div:first-child {
            margin-right: 1rem;
        }

        .address {
            height: 96px;
            margin-top: 1.5rem;
            background-color: #ffffff;
            filter: drop-shadow(0px 5px 28px rgba(174, 174, 174, 0.1));
            border-radius: 6px;
        }

        .address > div {
            display: flex;
            padding: 12px 16px;
            align-items: center;
        }

        .start-point .AddressWrapper{
            position: relative;
            width: 100%;
        }

        .start-point .AddressWrapper::after {
            content: '';
            width: 100%;
            border-bottom: 1px solid #e6e9ec;
            position: absolute;
            top: 0;
            left: 0;
            bottom: -12px;
            pointer-events: none;
        }

        .address > div img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .services div {
            flex: 1;
            height: 34px;
        }

        .services > div:first-child{
            border: 1px solid #E6E9EC;
            // border: 1px solid red;
            box-shadow: 0px 4px 168px rgba(152, 152, 152, 0.25);
            border-radius: 4px;
            min-width: 167.5px;
        }

        .services-quantity {
            display: flex;
            // justify-content: space-between;
            align-items: center;
        }

        .services-quantity img {
            width: 34px;
            height: 34px;
            padding: 10px;
            border: 1px solid #E6E9EC;
            box-shadow: 0px 4px 168px rgba(152, 152, 152, 0.25);
            margin: 0;
            cursor: pointer;
        }

        .services-quantity img:first-child {
            margin-left: 1rem;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .services-quantity img:last-child {
            border-left: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .quantity {
            height: 34px;
            width: 50px;
            color: #181918;
            font-size: 14px;
            font-weight: 500;
            padding-left: 22px;
            border: 1px solid #E6E9EC;
        }

        .dispatcher .title {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-top: .8rem;
            margin-bottom: .3rem;
        }

        .dispatcher .options {
            display: flex;
        }

        .dispatcher .options > div {
            flex: 1;
        }

        .dispatcher .options > div:first-child {
            display: flex;
            height: 34px;
            border-radius: 4px;
            min-width: 50%;
        }

        .dispatcher .options > div:last-child {
            padding-left: 1rem;
        }

        .dispatcher .options > div:first-child button {
            color: black;
            background-color: #ffffff;
            flex: 1;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            font-family: 'Roboto';
            border: 1px solid #e6e9ec;
        }

        .dispatcher .options > div:first-child > button:last-child{
            // border-left: 1px solid #e6e9ec;
        }

        .dispatcher .options > div:first-child > button:first-child {
            border: 1px solid $secondary-light-0;
            background: rgba(227, 240, 232, 0.6);
            border-radius: 4px 0px 0px 4px;
        }

        .dispatcher .options.assign > div:first-child > button:first-child {
            border-color: #e6e9ec;
            background: #ffffff;
        }

        .dispatcher .options.assign > div:first-child > button:last-child {
            border-color: $secondary-light-0;
            background: rgba(227, 240, 232, 0.6);
            border-radius: 0px 4px 4px 0px;
        }

        .customer-payment {
            display: flex;
            margin-top: .8rem;
        }

        .group-button {
            flex: 1;
        }

        .group-button.customer {
            min-width: 50%;
        }

        .group-button.payment {
            margin-left: 1rem;
        }

        .group-button .title {
            display: flex;
        }

        .group-button .title span{
            font-size: 14px;
            font-weight: 500;
            color: #000000;
        }

        .group-button.customer .title span{
            margin-left: .5rem;
        }
        
        .group-button.customer .title span{
            //text-transform: uppercase;
        }

        .group-button .btn-content {
            display: flex;
            height: 34px;
            width: 100%;
            margin-top: .3rem;
        }

        .group-button .btn-content button{
            color: black;
            background-color: #ffffff;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            font-family: 'Roboto';
            border: 1px solid #e6e9ec;
            width: 50%;
        }
        
        .group-button .btn-content > button:first-child {
            border: 1px solid $secondary-light-0;
            background: rgba(227, 240, 232, 0.6);
            border-radius: 4px 0px 0px 4px;
        }

        .group-button .btn-content > button:last-child:focus {
            // border: 1px solid $secondary-light-0;
            // background: rgba(227, 240, 232, 0.6);
            // border-radius: 0px 4px 4px 0px;
        }

        .coupon {
            margin-top: .8rem;
        }

        .coupon .title {
            display: flex;
            margin-bottom: .3rem;
        }

        .coupon .title span {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-left: .5rem;
        }

        .coupon .content {
            display: flex;
            width: 100%;
        }

        .coupon .content .left-content,  .coupon .content .right-content{
            flex: 1;
            display: flex;
        }

        .coupon .content .left-content {
            min-width: 50%;
            position: relative;
        }

        .coupon .content .right-content {
            margin-left: 1rem;
        }

        .coupon .content .left-content input{
            width: 100%;
        }

        .coupon .content .left-content span{
            position: absolute;
            top: 6px;
            right: .5rem;
        }

        .coupon .content input {
            height: 34px;
            color: $text-light-0;
            font-size: 14px;
            font-weight: 400;
            font-family: 'Roboto';
            padding-left: 8px;
            border: 1px solid #E6E9EC;
            border-radius: 4px;
        }

        .coupon .content .right-content input {
            flex: 1;
            //width: 100px;
            min-width: 50px;
            width: 60px;
            padding-left: 6px;
        }

        .coupon .content input::placeholder {
            font-size: 13px;
            font-weight: 400;
            color: #BBBCBC;
            font-family: 'Roboto';
        }

        .coupon .content span {
            font-size: 14px;
            font-weight: 400px;
        }

        .coupon .content .left-content span{
            color: $secondary-light-0;
        }

        .coupon .content .right-content span{
            color: white;
            background-color: #bdbdbd;
            padding: 7px 3px;
            font-size: 12px;
            width: 54px;
            display: block;
            border-radius: 0 4px 4px 0;
        }

        // Action buttons
        .ActionBtns.light-mode {
            background-color: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            height: 56px;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            padding: 0px 32px;
            gap: 16px;
        }

        .ActionBtns.light-mode button {
            height: 36px;
            /* Grey 3 */
            border: 1px solid #DADADA;
            border-radius: 4px;
            padding: 4px 11px;
            width: 50%;
            text-wrap: nowrap;
        }
        
        .ActionBtns.light-mode button:first-child {
            min-width: 50%;
            background-color: #ffffff;
            color: $secondary-light-0;
        }

        .ActionBtns.light-mode .CreateBtn {
            background-color: $secondary-light-0;
        }

        .EstimateInfo {
            visibility: hidden;
        }
    }
}

.BookingInfo.Switchboard {
    width: 100%;
}

.BookingInfo.Switchboard .Heading {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.BookingInfo.Switchboard .EstimateValue {
    padding-right: 0.8rem !important;
}

.BookingInfo.Switchboard .InfoCol {
    margin-bottom: 0.5rem !important;
}
