@import '../../../../scss/variables';

.DatePickerWrapper {
    .daterangepicker {
        //background: $bg-dark-1 !important;
        background: $bg-light-1 !important;
        border: 1px solid $bd-light-4;

        .rangecontainer > div:first-child {
            background-color: $secondary-light-0 !important;
            color: $text-light-7 !important;
        }

        .rangecontainer > div {
            background-color: $bg-light-2 !important;
            border: 1px solid $bd-light-4 !important;
            color: $text-light-5 !important;
        }


        .fromDateHourContainer {
            //border: 1px solid $app-2 !important;
            border: 1px solid $bd-light-1 !important;
            padding-left: 10px;
            .inputDate {
                border: 1px solid $bd-light-4 !important;
                border-radius: 0.125rem;
                padding: 0.25rem 0.5rem;
                background-color: $bg-light-2 !important;
                color: $text-light-5 !important;
                width: 171px !important;
                font-size: 12px;
                font-weight: 500;
            }
        }
        .multipleContentOnLine select {
            border-radius: 0.125rem;
            padding: 0.25rem;
            background-color: $bg-light-2 !important;
            border: 1px solid $bd-light-4;
            color: $text-light-5 !important;
        }

        .dateTimeLabel{
            color: $text-light-5 !important;
            font-weight: 500 !important;
        }

        .maxDateLabel {
            color: $app-1 !important;
            font-size: small !important;
        }
        .calendarGrid {
            color: black;
        }
        .calendarCell {
            color: $text-light-5 !important;
            background: rgba(227, 240, 232, 0.6) !important;
        }

        .activeNotifier {
            color: black;
        }

        .applyButton {
            background-color: $secondary-light-0 !important;
        }
    }
    .DatePickerInput {
        border-radius: 0.125rem;
        padding: 0 5px 0 10px;
        min-height: 1.5rem;
        height: 28px !important;
        width: 230px;
        box-sizing: border-box;
        font-size: .8rem;
        font-weight: 4000;
        color: $text-light-2;
        font-family: 'Roboto';
        //background: $bg-dark-0;
        background: $bg-light-2;
        border: 1px solid $bd-light-4 !important;

    }
}

.Active .DatePickerWrapper {
    pointer-events: none;
}
