@import '../../../scss/variables';

$bottom-height: 120px;

.BookingDetail {
    width: 435px;
    height: 100%;
    position: relative;
    padding: 15px 20px 20px;

    @import 'AddressField';
    @import 'PickupTimeField';

    .FormBooking {
        position: relative;
        height: 100%;
    }

    .car-found {
        .car-found {
            filter: invert(93%) sepia(17%) saturate(7404%) hue-rotate(339deg) brightness(102%) contrast(87%);
        }
    }

    .finished-booking {
        filter: invert(20%) sepia(88%) saturate(1888%) hue-rotate(205deg) brightness(79%) contrast(94%);
    }

    .customer-info {
        margin-top: 1.6rem;
    }

    .customer-info > div {
        flex: 1 !important;
    }

    .customer-info > div:first-child {
        margin-right: 1rem;
    }

    .address {
        height: 96px;
        margin-top: 1.5rem;
        background-color: #ffffff;
        filter: drop-shadow(0px 5px 28px rgba(174, 174, 174, 0.1));
        border-radius: 6px;
    }

    .address > div {
        display: flex;
        padding: 12px 16px;
        align-items: center;
    }

    .start-point .AddressWrapper{
        position: relative;
        width: 100%;
    }

    .start-point .AddressWrapper::after {
        content: '';
        width: 100%;
        border-bottom: 1px solid #e6e9ec;
        position: absolute;
        top: 0;
        left: 0;
        bottom: -12px;
        pointer-events: none;
    }

    .address > div img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .services div {
        flex: 1;
        //height: 34px;
    }

    .services > div:first-child{
        border: 1px solid #E6E9EC;
        // border: 1px solid red;
        box-shadow: 0px 4px 168px rgba(152, 152, 152, 0.25);
        border-radius: 4px;
        min-width: 167.5px;
    }

    .services-quantity {
        display: flex;
        // justify-content: space-between;
        align-items: center;
    }

    .services-quantity img {
        width: 34px;
        height: 34px;
        padding: 10px;
        border: 1px solid #E6E9EC;
        box-shadow: 0px 4px 168px rgba(152, 152, 152, 0.25);
        margin: 0;
        cursor: pointer;
    }

    .services-quantity img:first-child {
        margin-left: 1rem;
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .services-quantity img:last-child {
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .quantity {
        height: 34px;
        width: 50px;
        color: #181918;
        font-size: 14px;
        font-weight: 500;
        padding-left: 22px;
        border: 1px solid #E6E9EC;
    }

    .dispatcher .title {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        margin-top: .8rem;
        margin-bottom: .3rem;
    }

    .dispatcher .options {
        display: flex;
    }

    .dispatcher .options > div {
        flex: 1;
    }

    .dispatcher .options > div:first-child {
        display: flex;
        height: 34px;
        border-radius: 4px;
        min-width: 50%;
    }

    .dispatcher .options > div:last-child {
        padding-left: 1rem;
    }

    .dispatcher .options > div:first-child button {
        color: black;
        background-color: #ffffff;
        flex: 1;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Roboto';
        border: 1px solid #e6e9ec;
    }

    .dispatcher .options > div:first-child > button:last-child{
        // border-left: 1px solid #e6e9ec;
    }

    .dispatcher .options > div:first-child > button:first-child {
        border: 1px solid $secondary-light-0;
        background: rgba(227, 240, 232, 0.6);
        border-radius: 4px 0px 0px 4px;
    }

    .dispatcher .options.assign > div:first-child > button:first-child {
        border-color: #e6e9ec;
        background: #ffffff;
    }

    .dispatcher .options.assign > div:first-child > button:last-child {
        border-color: $secondary-light-0;
        background: rgba(227, 240, 232, 0.6);
        border-radius: 0px 4px 4px 0px;
    }

    .customer-payment {
        display: flex;
        margin-top: .8rem;
    }

    .group-button {
        flex: 1;
    }

    .group-button.customer {
        min-width: 50%;
    }

    .group-button.payment {
        margin-left: 1rem;
    }

    .group-button .title {
        display: flex;
    }

    .group-button .title span{
        font-size: 14px;
        font-weight: 500;
        color: #000000;
    }

    .group-button.customer .title span{
        margin-left: .5rem;
    }
    
    .group-button.customer .title span{
        //text-transform: uppercase;
    }

    .group-button .btn-content {
        display: flex;
        height: 34px;
        width: 100%;
        margin-top: .3rem;
    }

    .group-button .btn-content button{
        color: black;
        background-color: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Roboto';
        border: 1px solid #e6e9ec;
        width: 50%;
    }
    
    .group-button .btn-content > button:first-child {
        border: 1px solid $secondary-light-0;
        background: rgba(227, 240, 232, 0.6);
        border-radius: 4px 0px 0px 4px;
    }

    .group-button .btn-content > button:last-child:focus {
        // border: 1px solid $secondary-light-0;
        // background: rgba(227, 240, 232, 0.6);
        // border-radius: 0px 4px 4px 0px;
    }

    .coupon {
        margin-top: .8rem;
    }

    .coupon .title {
        display: flex;
        margin-bottom: .3rem;
    }

    .coupon .title span {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        margin-left: .5rem;
    }

    .coupon .content {
        display: flex;
        width: 100%;
    }

    .coupon .content .left-content,  .coupon .content .right-content{
        flex: 1;
        display: flex;
    }

    .coupon .content .left-content {
        min-width: 50%;
        position: relative;
    }

    .coupon .content .right-content {
        margin-left: 1rem;
    }

    .coupon .content .left-content input{
        width: 100%;
    }

    .coupon .content .left-content span{
        position: absolute;
        top: 6px;
        right: .5rem;
    }

    .coupon .content input {
        height: 34px;
        color: $text-light-0;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Roboto';
        padding-left: 8px;
        border: 1px solid #E6E9EC;
        border-radius: 4px;
    }

    .coupon .content .right-content input {
        flex: 1;
        //width: 100px;
        min-width: 50px;
        width: 60px;
        padding-left: 8px;
    }

    .coupon .content input::placeholder {
        font-size: 13px;
        font-weight: 400;
        color: #BBBCBC;
        font-family: 'Roboto';
    }

    .coupon .content span {
        font-size: 14px;
        font-weight: 400px;
    }

    .coupon .content .left-content span{
        color: $secondary-light-0;
    }

    .coupon .content .right-content span{
        color: white;
        background-color: #bdbdbd;
        padding: 7px 3px;
        font-size: 12px;
        width: 54px;
        display: block;
        border-radius: 0 4px 4px 0;
    }

    // Action buttons
    .ActionBtns.light-mode {
        background-color: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        height: 56px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        gap: 16px;
        justify-content: flex-end;
    }

    .ActionBtns.light-mode button {
        height: 36px;
        /* Grey 3 */
        border: 1px solid #DADADA;
        border-radius: 4px;
        padding: 4px 16px;
        background-color: $secondary-light-0;
        color: $text-light-7;
    }
    
    .ActionBtns.light-mode img{
        width: 34px;
        height: 34px;
        cursor: pointer;
    }

    .ActionBtns.light-mode img:first-child{
        position: absolute;
        left: 20px;
    }

    .operator-bookingfrom {
        background-color: $bg-light-4;
        height: 34px;
        border: 1xp solid gray;
        width: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-top: 1.2rem;
        
        span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            color: $text-light-1;
        }

        .operator > span:last-child, .bookingfrom > span:last-child {
            color: $text-light-8;
            font-family: 'Roboto';
        }
    }

    .operator-bookingfrom > div {
        flex: 1;
    }

    .operator-bookingfrom > div:first-child {
        min-width: 50%;
        padding: 0px 8px;
    }

    .operator-bookingfrom > div:last-child {
        margin-left: 1rem;
        padding: 0px 8px;
    }



    
}

.BookingDetail.zoomout {
    width: 35%;
}


