@import '../../../../scss/variables';

.InputWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    width: 100%;
    margin-right: 1.2rem;

    .Input {
        // background: $bg-dark-0;
        //border: 1px solid transparent;
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 0.125rem;
        padding: 0.25rem 1.2rem 0.25rem .5rem;
        min-height: 1.5rem;
        padding-right: 1.2rem;

        &:focus {
            //border-color: $app-1;
            border-color: $secondary-light-0;
        }
    }

    .search-input {
        width: 16rem;
    }
}
