.address-detail {
    .header {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        font-family: 'Roboto';
        margin-bottom: 1rem;
    }

    .content {
        display: flex;
        margin-bottom: .6rem;
        img {
            width: 13px;
            height: 13px;
        }
        p {
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #282828;
            padding-left: .25rem;
            margin-top: -1px;
        }
    }
}

.address-detail.hide {
    display: none;
}
