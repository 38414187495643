@import '../../../scss/variables';

.Filter {
    display: flex;
    flex-direction: column;
    //background: $bg-dark-1;
    background: #ffffff;
    color: black;
    padding: 0.5rem;
    padding-top: 1rem;
    row-gap: 0.5rem;
    max-height: 98px;
    // height: 98px;
    margin-bottom: 1rem;

    transition: height padding ease-in-out 0.3s;
    .Row {
        display: flex;
        width: 100%;
        // column-gap: 1rem;
        // row-gap: 0.5rem;
        // flex-wrap: wrap;
    }

    .Row.Second {
        display: flex;
        column-gap: 10px;
        margin-top: .5rem;
    }

    .SelectWrapper {
        flex-direction: row;
        align-items: center !important;
    }

    .SelectWrapper label {
        // width: 190px !important;
        // width: fit-content !important;
        white-space: nowrap !important;
        margin-right: 5px;
    }

    .IconCollapseUp {
        position: absolute;
        top: 60px;
        right: 25px;
        // left: "calc(100vw - 830px)",
        cursor: pointer;
        fill: orange !important;
    }

    .IconCollapseDown {
        position: absolute;
        top: 60px;
        right: 25px;
        // left: "calc(100vw - 830px)",
        cursor: pointer;
        fill: orange !important;
    }

    .search-icon {
        cursor: pointer !important;
        margin-top: 5px !important;
        font-size: 20px !important;
    }


    .search-icon:hover {
        fill: orange !important;
    }

    .MuiPaper-root {
        min-width: 283px !important;
    }
}

.Filter.Hide {
    height: 0px;
    padding: 0;
}

@media only screen and (max-width: 1730px) {
    // .InputWrapper.search-input {
    //     width: 256px !important;
    // }
}
