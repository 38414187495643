@import '../../../scss/variables';
.WaitingCalls {
    @import 'WaitingCallTable';

    pointer-events: all;

    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 999;

    // width: 410px;
    // height: 90px;

    // position: relative;
    // z-index: 99;
}
