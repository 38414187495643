$label-color: #bbb;
//$label-color: #5e5e5e;
$disabled-color: #ddd;
$toggle-color: #edba29;
$focus-color: #f69403;

.toggle-switch {
    position: relative;
    margin-right: 10px;
    width: 95px;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    text-align: left;

    &-checkbox {
        display: none;
    }

    &-label {
        display: block;
        cursor: pointer;
        border: 0 solid $focus-color;
        border-radius: 20px;
        overflow: hidden;
        margin: 0;
    }

    &-switch {
        display: block;
        width: 24px;
        margin: 5px;
        background: white;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 60px;
        border: 0px solid $label-color;
        border-radius: 20px;
        transition: all 0.2s ease-in 0s;
    }

    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            height: 34px;
            line-height: 34px;
            font-size: 14px;
            width: 50%;
            color: white;
            float: left;
            padding: 0;
            box-sizing: border-box;
        }

        &:before {
            content: attr(data-yes);
            // text-transform: uppercase;
            padding-left: 10px;
            background-color: $focus-color;
            color: white;
        }
    }

    &-inner:after {
        content: attr(data-no);
        // text-transform: uppercase;
        padding-right: 10px;
        background-color: $label-color;
        color: white;
        text-align: right;
    }

    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0 !important;
        }
        .toggle-switch-switch {
            right: 0px !important;
        }
    }

    &.small-switch {
        width: 36px;
        .toggle-switch-inner {
            &:after,
            &:before {
                content: '';
                height: 18px;
                line-height: 18px;
            }
        }
        .toggle-switch-switch {
            width: 16px;
            right: 16px;
            margin: 2px;
        }
    }
}
