@import '../../../scss/variables';

.menu-context {
    background-color: $bg-dark-1;
    border: 1px solid $app-1;
    //border: 1px solid $bg-dark-1;
    border-radius: 3px;
    box-shadow: 2px 3px 10px rgba($color: $app-2, $alpha: .4);
    h3 {
        background-color: $bg-dark-2;
        font-size: .85rem;
        padding: 5px 10px;
        color: $app-1;
        font-weight: bold;
    }

    .menu-item p {
        padding: 6px 5px;
        border-bottom: 1px solid $bg-dark-2;
        color: #dde2eb;
        cursor: pointer;
    }

    .menu-item p:hover {
        background-color: $bg-dark-0;
        font-weight: bold;
    }

}

.menu-context.hide {
    display: none;
}