.MapPicker {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 65%;
    height: 100%;
    pointer-events: none;
    position: relative;
    // padding-right: 1rem;

    .MapContainer {
        // margin-top: 0.5rem;
        height: 100%;
        pointer-events: all;
    }

    .MarkerInfoText {
        display: flex;
        flex-direction: column;
        color: #000;
        font-size: 12px;
        background-color: #21bdd1bb;
        border-radius: 4px;
        top: 10px;
        left: 10px;
        width: max-content !important;
        height: max-content !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .estimate-control {
        display: flex;
        background: #FFFFFF;
        opacity: 0.95;
        box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.07);
        border-radius: 8px;
        width: 300px;
        height: 48px;
        border: none;
        color: $text-light-6;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }

    .leaflet-bottom.leaflet-left{
        left: 50% !important;
        transform: translate(-50%, 0);
    }

    .estimate-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex: 1;
    }

    .estimate-content.distance::after, .estimate-content.price::after  {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 24px;
        border-right: 1px solid lightgray;
        transform: translate(0, -50%);
    }

    .estimate-content > p:first-child {
        color: $text-light-6;
        font-size: 18px;
        font-weight: 600;
    }
}
