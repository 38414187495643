@import '../../../scss/variables';
// $color-grid: #dde2eb;
$color-grid: #fff;

.WaitingCallTable {
    transform: translateY(0);
    //transition: all 0.2s linear;
    width: 435px;
    height: 80px;
    display: flex;
    flex-direction: column;

    transition: all 0.1s ease-in-out;

    .Heading {
        font-weight: bold;
        // padding: 0.3rem 0.5rem;
        //background-color: $app-1;
        background: rgba(24, 25, 24, .95);
        height: 26px;

        //color: #000;
        color: #ffffff;

        display: flex;
        justify-content: space-between;
        align-items: center;

        position: relative;

        .fulltable-icon {
            position: absolute;
            top: 9px;
            right: 30px;
            cursor: pointer;
        }

        .fulltable-icon.hide {
            display: none;
        }

        span {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            padding-left: .5rem;
        }
    }

    table {
        background-color: $bg-dark-0;
        padding: 0;

        th {
            padding-left: .5rem;
        }

        tr {
            padding: 0;
            border-bottom: 1px solid $app-0;
            th {
                padding: 0.3rem 0.5rem;
                font-weight: bold;
                border-right: 1px solid $app-0;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        tbody {
            min-height: 5rem;
        }
    }

    #myGrid {
        position: unset !important;
    }

    .grid-wrapper {
        flex: 1;
        background-color: $bg-dark-1 !important;
    }

    .ag-theme-alpine .ag-root-wrapper {
        border: 1px solid $bg-dark-1 !important;
    }

    //Custom AgGrind Waitting Calls
    .ag-theme-alpine .ag-cell {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ag-theme-alpine .ag-header-cell {
        padding-left: 5px;
        padding-right: 5px;
    }

    .ag-header {
        height: 25px !important;
        min-height: 25px !important;

        background-color: $bg-dark-1;
        border: 1px solid $bg-dark-2;
    }

    .ag-header-row {
        height: 25px !important;
        color: $color-grid !important;
    }

    .waiting-calls .ag-body-viewport.ag-layout-normal {
        overflow-y: hidden !important;
    }

    .waiting-calls .ag-cell {
        height: 25px !important;
        display: flex;
        align-items: center;
    }

    // .ag-row {
    //     height: 25px !important;
    // }

    // .ag-center-cols-container {
    //     height: 25px !important;
    // }

    .ag-header-cell-text {
        font-weight: normal;
        text-transform: uppercase;
    }

    .ag-body-viewport {
        background-color: $bg-dark-2;
        color: $color-grid !important;
    }

    .waiting-calls .ag-row {
        border: none !important;
        border-bottom: 1px solid $bg-dark-2 !important;
        background-color: $bg-dark-1;
        color: $color-grid;
        height: 25px !important;
    }

    .waiting-calls .ag-row:hover {
        cursor: pointer !important;
    }

    .ag-theme-alpine .ag-row {
        // height: 32px !important;
    }

    .ag-center-cols-container {
        height: fit-content !important;
    }

    .ag-cell-focus {
        border-color: transparent !important;
    }
}

.WaitingCallTable.full-height {
    height: 200px;
}

.TableHide {
    transform: translateY(calc(100% - 1.5rem));
}
