@import '../../../scss/variables';

.NewBookingContainer {
    @import 'NewBooking';
    @import 'BookingInput';

    position: absolute;
    transform: translateX(0%) translateY(0);
    z-index: 2;
    //transition: all 0.2s cubic-bezier(0.5, 0, 0.5, 1);

    left: 0;
    top: 0;
    width: 100%;

    //height: calc(100vh - 3.5rem);
    height: 100vh;
    // background: transparent;
    pointer-events: none;

    .Rnd {
        cursor: default !important;
        //overflow: hidden;
        //background: $bg-dark-2;
        background: $bg-light-1;
        pointer-events: all;
        box-shadow: gray 1px 2px 10px 0px;
    }
}

.NewBookingContainerHide {
    //transform: translateX(calc(-100%)) scale(0.00001, 0.00001);
    transform: translateX(calc(-100%));
    //display: none;
}
