.parent-icon-status {
    display: flex;
    text-transform: uppercase;
    font-size: .8rem;

    img {
        margin-right: 5px;
    }

    span {
        color: #181918 !important;
        font-weight: 500 !important;
        font-family: 'Roboto';
        // font-size: 12px !important;
    }
}

.div-status {
    padding-top: 10px !important;
    .p-reason-cancelled-container {
        display: flex;
    }
    .p-reason-cancelled {
        color: $text-light-1 !important;
        font-size: 13px;
        font-weight: 400;
        padding-left: 25px;
        width: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .a-reason-cancelled {
        font-size: 13px;
        font-weight: 400;
        color: $text-light-0;
        text-decoration: underline;
        cursor: pointer;
    }
}


.child-icon-status {
   
}