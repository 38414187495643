@import 'scss/variables';
@import '_RadioStatus.scss';
// $color-grid: #dde2eb;
$color-grid: #fff;

.grid-wrapper {
    flex: 1;
    position: relative;
    z-index: 1;
    border: none;
    // border: 1px solid red;
    background-color: $bg-light-2;
    padding: 15px 8px 5px 10px;
}

.ag-root {
    display: initial !important;
}

.ag-root-wrapper {
    border: none !important;
}

.ag-header-cell {
    padding-left: 5px !important;
}

.ag-cell {
    height: 92px !important;
    padding: 2px 15px 5px 5px !important;
}

.ag-cell-value span{
    width: 100% !important;
    font-size: 13px;
    font-family: 'Roboto' !important;
    // color: #000000 !important;
    // font-weight: 400 !important;
}

.ag-switchboard .ag-cell {
    height: 42px !important;
    padding: 5px 15px 5px 0 !important;
}

.ag-cell-wrapper {
    align-items: flex-start;
}

.ag-row {
    border: none !important;
    border-bottom: 1px solid $bd-light-2 !important;
    border-radius: 4px;
    background-color: #fff !important;
    height: 92px !important;
}

.ag-header {
    height: 30px !important;
    min-height: 30px !important;
    border: 1px solid $bd-light-0;
    background-color: $bg-light-1;
}

.ag-header-cell-text {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    color: #282828;
    font-family: 'Roboto' !important;
}

.ag-header-row {
    height: 30px !important;
}

.ag-body-viewport {
    // background-color: $bg-dark-2;
}

.ag-cell-wrap-text {
    //word-break: break-word !important;
    // overflow: hidden;
    min-height: 100% !important;
    white-space: nowrap;
}

.btn-refresh.hide {
    display: none !important;
}

.ag-selectable {
    //user-select: none !important;
}

// .ag-row-focus {
//     border-color: orange !important;
//     border-top: 1px solid orange !important;
// }

/*
Custom address
*/
.div-other {
    line-height: 20px !important;
}
.div-address {
    display: flex;
}

.div-address a {
    text-decoration: underline;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    //color: #181918;
    color: $text-light-0;
    cursor: pointer;
}

.div-address span {
    font-size: 14px;
    font-weight: 400;
    //color: #282828;
    color: $text-light-4;
    font-family: 'Roboto';
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service-name, .driver-name {
    font-size: 14px;
    font-weight: 500;
    color: $text-light-0;
}

.car-info, .driver-phone {
    font-size: 12px;
    font-weight: 400;
    color: $text-light-1;
}

.label-other-info {
    font-size: 13px;
    font-weight: 400;
    color: $text-light-1;
}

.content-other-info {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: $text-light-4 !important;
}

.div-note {
    display: flex;
}

.car-found {
    //filter: invert(93%) sepia(17%) saturate(7404%) hue-rotate(339deg) brightness(102%) contrast(87%);
    filter: invert(56%) sepia(86%) saturate(515%) hue-rotate(359deg) brightness(102%) contrast(106%);
}

.finished-booking {
    filter: invert(20%) sepia(88%) saturate(1888%) hue-rotate(205deg) brightness(79%) contrast(94%);
}

.booking-reservation {
    filter: invert(12%) sepia(74%) saturate(1755%) hue-rotate(332deg) brightness(78%) contrast(110%);
}

@mixin text-ellipsis($lines: 1) {
    text-overflow: ellipsis;
    overflow: hidden;
    @if ($lines > 1) {
        white-space: normal !important;
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        word-break:  break-word !important;
    } @else {
        white-space: nowrap;
    }
}

.note-content{
    line-height: 24px !important;
    font-size: 13px;
    font-weight: 400;
    @include text-ellipsis($lines: 3);
}

.link-detail-note {
    position: absolute;
    bottom: -11px;
    right: 5px;
    text-decoration: underline;
    color: $text-light-0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

/*Grid Finished Booking*/
.p-total-price, .p-distance {
    color: #219739;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Roboto';
    line-height: 24px;
}

.p-distance {
    color: #181918;
}

.DriverCancelBlink {
    animation: blinker 0.6s cubic-bezier(1, 0, 0, 1) infinite alternate;
}
@keyframes blinker {
    to {
        opacity: 0;
    }
}
