@import '../../../scss/variables';

.TripDetailContainer {
    background: $bg-light-1;
    border-left: 1px solid $bd-light-3;
    position: absolute !important;
    z-index: 10;
    right: 0 !important;
    width: 435px;
    //width: 100%;
    //height: calc(100vh - 200px);
    height: 100vh;
    box-shadow: -6px 4px 5px rgba(61, 61, 61, 0.08);

    // padding: 15px 20px 20px;

    .group-icons {
        display: flex;
        gap: .6rem;
        position: absolute;
        top: 16px;
        right: 10px;
        
        img {
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }

    .Title {
        color: $secondary-light-0;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Roboto';
        // line-height: 20px;
        letter-spacing: 0.005em;
        

        .left-title {
            display: flex;
        }

        .trip-status {
            color: $text-light-0;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            display: flex;
            margin-left: 10px;
            align-items: center;
        }

        .trip-status img {
            width: 14px;
            height: 14px;
        }

        .trip-status span {
        }
    }

    .Body {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        @import 'BookingDetail';
        @import 'MapTracking';
    }
}

.TripDetailContainer.zoomout {
    width: 100%;
    z-index: 8;
}