@import '../../../scss/variables';

.Nav {
    background-color: $bg-dark-1;
    padding: 0.2rem 0;
    width: 100%;
    // display: flex;
    align-items: center;

    .LogoLink {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0 0.5rem;

        .Logo {
            width: 2.5rem;
            padding-right: 0.5rem;
        }

        .Heading {
            color: #fff;
            display: flex;
            flex-direction: column;
        }

        .Heading span:first-child {
            font-size: 0.75rem;
        }

        .Heading span:last-child {
            font-size: 0.6rem;
            color: #edab25;
            font-weight: 400;
        }
    }
    .Language {
        .Active {
            color: $app-1;
        }
    }
}
