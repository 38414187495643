@import '../../../scss/variables';

.PickUpTimeWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    margin-top: .8rem;
    
    label {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
    }
}
