@import '../../../../scss/variables';

.SelectWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;

    /**
    * Main wrapper
    */
    .select-search {
        position: relative;
        // font-family: 'Nunito Sans', sans-serif;
        box-sizing: border-box;
        * {
            box-sizing: inherit;

            &::after,
            &::before {
                box-sizing: inherit;
            }
        }
    }

    /**
    * Value wrapper
    */
    .select-search__value {
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: calc(50% - 9px);
            right: 15px;
            width: 11px;
            height: 11px;
            color: #fff;
        }
    }

    /**
    * Input
    */
    .select-search__input {
        display: block;
        min-height: 1.75rem;
        width: 100%;
        padding: 0 40px 0 16px;
        background: $bg-dark-0;
        border: 1px solid transparent;
        // box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 1px;
        outline: none;
        font-size: small;
        text-align: left;
        text-overflow: ellipsis;
        line-height: 0.75rem;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

        &:not([readonly]):focus {
            cursor: initial;
        }
    }

    /**
    * Options wrapper
    */
    .select-search__select {
        background: $bg-dark-0;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.404);
    }

    /**
    * Options
    */
    .select-search__options {
        list-style: none;
    }

    /**
    * Option row
    */
    .select-search__row:not(:first-child) {
        border-top: 1px solid $bg-dark-1;
    }

    /**
    * Option
    */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: $bg-dark-2;
        border: none;
        outline: none;
        // font-family: 'Noto Sans', sans-serif;
        font-size: small;
        text-align: left;
        cursor: pointer;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option {
        &.is-selected {
            background: $app-2;
            color: #fff;
        }

        &.is-highlighted,
        &:not(.is-selected):hover {
            background: $app-1;
        }

        &.is-highlighted.is-selected,
        &.is-selected:hover {
            background: $app-2;
            color: #fff;
        }
    }

    /**
    * Group
    */
    .select-search__group-header {
        font-size: 10px;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
    * States
    */
    .select-search {
        &.is-disabled {
            opacity: 0.5;
        }

        &.is-loading .select-search__value::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
            background-size: 11px;
        }

        &:not(.is-disabled) .select-search__input {
            cursor: pointer;
        }
    }

    /**
    * Modifiers
    */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple)
        .select-search__value::after {
        transform: rotate(45deg);
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        pointer-events: none;
    }

    .select-search--multiple {
        .select-search__input {
            cursor: initial;
            border-radius: 3px 3px 0 0;
        }

        &:not(.select-search--search) .select-search__input {
            cursor: default;
        }
    }

    .select-search:not(.select-search--multiple) {
        .select-search__input:hover {
            border-color: $app-1;
        }

        .select-search__select {
            position: absolute;
            z-index: 2;
            top: 35px;
            right: 0;
            left: 0;
            border-radius: 2px;
            overflow: auto;
            max-height: 360px;
        }
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: #888;
    }
}
