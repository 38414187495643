.MapTracking {
    width: 0%;
    height: 100%;
    pointer-events: none;
    //padding-right: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .MapContainer {
        //margin-top: 0.5rem;
        height: 100%;
        width: 100%;
        pointer-events: all;
    }
}

.MapTracking.zoomout {
    width: 65%;
}
