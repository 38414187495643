@import '../../../scss/variables';

.FilterWrapper {
    .TripFilter {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: $bg-light-3;
        border-radius: 4px;
        padding: 15px;

        .TotalDriver {
            //background: $bg-dark-0;
            width: 12rem;
            height: inherit;
            position: relative;
            margin-right: 1rem;
            text-align: center;
            border: 1px solid $bd-light-3;
            border-radius: 4px;

            .TotalText {
                height: 3rem;
                display: grid;
                align-items: end;
                font-size: 14px;
                color: $text-light-8;
            }

            .TotalCount {
                height: 6rem;
                display: grid;
                align-items: start;
                font-size: 2.5rem;
                padding-top: 0.25rem;
                color: $text-light-8;
            }
        }

        .StatusFilter {
            //background: $bg-dark-0;
            background: $bg-light-2;
            color: $text-light-8;
            width: inherit;
            height: max-content;
            padding: 0.25rem 1rem;
            border: 1px solid $bd-light-3;
            border-radius: 4px;

            .StatusGroup {
                display: flex;
                flex-direction: row;

                .CheckBoxGroup {
                    width: 50%;
                }
            }
        }
    }
}

.FilterHide {
    display: none;
}

.FilterToggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.25rem 0;
    cursor: pointer;
    border-radius: 0.125rem;
    color: $app-1;

    &:hover {
        //background: $bg-dark-2;
    }
}
