@import '../../../../scss/variables';

.custom-button {
    // height: 32px;
    background-color: $bg-light-0;
    color: #000000;
    border-radius: 4px;
    padding: 0px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 30px;
    letter-spacing: 0.5px;
}

.custom-button.primary {
    background-color: $secondary-light-0;
    color: #ffffff;
}

.custom-button.danger {
    background-color: #ef5816;
    color: #ffffff;
}

.custom-button.outline {
    background-color: $bg-light-2;
    color: $text-light-0;
    border: 1px solid $bd-light-1
}

.custom-button.large {
    padding: 12px 24px;
}