@import '../../../scss/variables';
.tabs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .nav.nav-tabs {
        // background-color: $bg-dark-1;
        background-color: $bg-light-2;
        border-bottom: 1px solid $bd-light-2;
        display: flex;
        gap: 15px;
        height: 32px;
        padding-left: 10px;
        
        .nav-link {
            color: $text-light-1;
            
        }

        .nav-link.active {
            color: $secondary-light-0;
            border-bottom: 2px solid $secondary-light-0;
        }

        li > a {
            padding: 5px 0 0 0;
            font-size: 0.85rem;
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            font-family: 'Roboto';
        }
    }

    .tab-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    @import 'Filter';
}
