
.AddressWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    //width: 100%;
    flex: 1;
}

.AddressWrapper.note {
    margin-top: 1rem;
    label {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
    }
}

.AddressWrapper.note input {
    height: 34px !important;
    padding: 0 !important;
    padding-left: 8px !important;
    font-size: 14px;
    font-weight: 400;
}

.AddressWrapper.note .MuiOutlinedInput-root {
    border: 1px solid #e6e9ec !important;
    padding-left: 8px !important;
}