@import 'scss/variables';

.grid-wrapper {
    flex: 1;
    position: relative;
    z-index: 1;
    border: none;
    background-color: $bg-light-2;
    height: 100%;
    margin-top: 1.8rem;
}

.ag-root-wrapper {
    border: none !important;
}

.ag-header {
    height: 44px !important;
    min-height: 44px !important;
    border: 1px solid $bd-light-3;
    background-color: $bg-light-1;
    border-radius: 4px;
}

.ag-header-cell {
    padding-left: 8px !important;
}

.ag-header-cell-text {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    color: $text-light-0;
    font-family: 'Roboto' !important;
}

.ag-header-row {
    height: 44px !important;
}

.ag-row {
    border: none !important;
    border-bottom: 1px solid $bd-light-2 !important;
    border-radius: 4px;
    background-color: #fff !important;
    height: 72px !important;
}

.ag-cell {
    padding: 5px 5px 5px 8px !important;
    height: 72px !important;
}

@mixin text-ellipsis($lines: 1) {
    text-overflow: ellipsis;
    overflow: hidden;
    @if ($lines > 1) {
        white-space: normal !important;
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        word-break:  break-word !important;
    } @else {
        white-space: nowrap;
    }
}
/*
    custom start address
*/
.div-start-address{
    line-height: 24px !important;
    font-size: 13px;
    font-weight: 400;
    @include text-ellipsis($lines: 2);
}

.link-detail-start-address {
    position: absolute;
    bottom: -11px;
    right: 5px;
    text-decoration: underline;
    color: $text-light-0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.parent-icon-status {
    display: flex;
    text-transform: uppercase;
    font-size: .8rem;

    img {
        margin-right: 5px;
    }

    span {
        color: #181918 !important;
        font-weight: 500 !important;
        font-family: 'Roboto';
        font-size: 12px !important;
    }
}

.p-driver-name {
    color: $text-light-0 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: -0.5% !important;
    line-height: 24px !important;
}

.p-driver-license {
    color: $text-light-1;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.5%;
    line-height: 24px !important;
}
