@import '../../../scss/variables';

.PreviewTrips {
    //height: calc(100vh - 2.5rem);
    height: 100vh;
    //background-color: $bg-dark-1;
    background-color: $bg-light-2;
    padding: 1rem 1rem 1rem 1rem;
    width: 40vw;
    position: relative;

    @import 'FilterTrip';
    @import 'Trips';
    @import 'GridPreviewTrips';

    h1 {
        color: $text-light-8;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        letter-spacing: -0.165px;
        font-family: 'Roboto';
        line-height: 20px;
    }

    .ToggleBtn {
        position: absolute;
        left: -1.5rem;
        top: 40%;
        height: 3.5rem;
        width: 1.5rem;
        //background: $app-1;
        background: $bg-btn-0;
        transition: all 0.2s linear;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        &:hover {
            //background-color: $app-0;
            background-color: $bg-btn-1;
        }
    }

    .SearchInput {
        width: 100%;
        padding: 0.5rem 0.75rem;
        //background: $bg-dark-2;
        background: $bg-light-2;
        border: 1px solid $bd-light-0;
        transition: all 0.2s linear;
        border-radius: 0.25rem;
        color: $text-light-0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:focus {
            //border-color: $app-1;
        }
    }
}
