@import '../../../scss/variables';

.SettingContainer {
    width: 100%;
    height: 100vh;
    background: rgba(24, 25, 24, 0.25);
    

    @import 'Setting';
}
