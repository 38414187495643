@import '../../../scss/variables';
.Bookings {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 6px 20px 0px 10px;

    .booking-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h1 {
            font-size: 22px;
            font-weight: 600;
            color: #1c1c1c;
        }

        span {
            height: 32px;
            width: 140px;
            background-color: $secondary-light-0;
            border-radius: 4px;
            padding: 4px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }



    @import 'Tabs';
    @import 'GridBooking';
    @import 'GridBookingSwitchboard';
    @import 'MenuContext';
    @import 'MenuActions';
    @import 'ModalConfirmCancel';
    @import 'CheckboxReason';
    @import 'ModalDetail';
}

.BookingsHidden {
    display: none !important;
}
