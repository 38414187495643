@import '../../../scss/variables';

.BookingInput {
    label {
        color: $text-1;
    }

    input {
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.3rem 0.75rem;
        background: $bg-dark-1;
        border: 1px solid $bg-dark-1;
        border-radius: 0.25rem;
        outline: none;
        transition: all 0.2s linear;

        &:focus {
            border-color: $app-2;
        }
    }
}
