#gridbooking_switchboard {
    .ag-cell-wrap-text {
        word-break: initial !important;
    }
    .ag-cell {
        height: 42px !important;
    }

    .ag-row {
        border: none !important;
        border-bottom: 1px solid #dde2eb !important;
    }
    
}
