@import '../../../scss/variables';

.confirm-cancel {
    width: calc(100% - 159.56px);
    height: 100vh;
    border: 1px solid red;
    background: rgba(24, 25, 24, 0.25);
    position: fixed;
    top: 0;
    right: 0;
    transition: width 0.2s linear;
    display: none;
    color: #000000;
    
    .content {
        position: absolute;
        width: 364px;
        min-height: 316px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #FFFFFF;
        border-radius: 4px;

        .reason-cancel {
            padding: 8px 16px;
        }

        h3 {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
            //border-bottom: 1px solid $bd-light-3;
            padding: 5px 0;
            margin-bottom: .4rem;
            margin-top: .2rem;
        }

        h3::after {
            content: '';
            position: absolute;
            height: 1px;
            background-color: $bd-light-3;
            width: 100%;
            top: 38px;
            left: 0px;
        }

        textarea {
            border: 1px solid $bd-light-3;
            width: calc(100% - 1.5rem);
            margin-left: 1.5rem;
            border-radius: 4px;
            height: 92px;
            margin-bottom: 8px;
            padding: 8px;
        }
    }

    .btn-group {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        bottom: 0;
        border-top: 1px solid $bd-light-3;
        width: 100%;
        height: 3rem;
        align-items: center;
        padding: 0 16px;

        button {
            flex: 1;
            border: 1px solid $bd-light-1;
            border-radius: 4px;
            width: 162px;
            height: 32px;
            padding: 4px 16px;
        }

        button:last-child {
            background-color: $secondary-light-0;
            color: $text-light-7;
            border-color: transparent;
        }
    }

}

.confirm-cancel.modal-sidebarclose {
    width: calc(100% - 46.83px);
}

.confirm-cancel.show {
    display: initial;
}
