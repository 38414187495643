@import '../../../scss//variables';

.SideBar {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    //background: $bg-dark-1;
    background: $bg-sidebar-0;

    //z-index: 30;
    .Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        height: 3rem;
        border-bottom: 1px solid $app-1;
        width: fit-content;
        margin:auto;
    }

    .Logo img{
        width: 2.5rem;
        // padding-right: 0.5rem;
    }

    .Menus {
        .CollapsedMenu {
            justify-content: center;
            padding: 0;
        }

        .SelectedMenu {
            //color: $app-1;
            //color: $secondary-light-0;
            border-left: 3px solid $app-1;
            background-color: #2d2d2d;
        }

        .Menu {
            cursor: pointer;
            padding: 0.5rem 0.5rem;
            min-height: 2.5rem;
            display: flex;
            align-items: center;
            width: 100%;
            transition: all 0.2s linear;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            //border-top: 1px solid $bg-dark-0;
            border-bottom: 1px solid #2A2A2A;

            &:hover {
                // border-color: $secondary-light-0;
                // color: $secondary-light-0;
                border-color: $app-1;
                color: $app-1;
            }

            .MenuIcon {
                //width: 3rem;
                padding-right: 0.5rem;
                // display: flex;
                // justify-content: center;
            }

            .Toggle {
                padding-right: 0rem;
            }


        }

        .Menu.CollapsedMenu.SelectedMenu{
            //border-left: 3px solid $secondary-light-0;
            border-left: 3px solid $app-1;
            background-color: #2d2d2d;
        }

        .NewBookingButton {
            // color: $text-0;
            // font-weight: bold;
        }

        .NewBookingButton.CollapsedMenu.SelectedMenu{
            //border-left: 3px solid $secondary-light-0;
            border-left: 3px solid $app-1;
        }

        .ToggleIcon {
            // padding: 0.75rem 1.5rem;
            height: 3.5rem;
        }

        .ToggleMenuIcon {
            font-size: 1rem;
            margin-left: 2px;
        }

        .ToggleIconCollapsed {
            padding: 0;
            display: flex;
            justify-content: center;
        }
    }
}

.SideBarOpenWidth {
    width: 11rem;
    // border: 1px solid green;
}
