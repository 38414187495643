.CheckBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
    //width: 100%;
    align-items: center;
    margin: 0.5rem 0;
    cursor: pointer;
    user-select: none;

    .CheckBoxLabel {
        margin-left: 0.25rem;
    }
}

.CheckBox.Cb-Filter {
    flex-direction: row-reverse;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    font-family: 'Roboto';
}
