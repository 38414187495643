@import '../../../scss/variables';

.Detail {
  &__Wrapper {
    width: 65%;
    background: $bg-light-2;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__Content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;

    .content-box {
      width: 50%;
      height: 100%;
      color: black;
      padding: 1rem;

      .table-region {
        color: $text-light-5;
        margin: 0 auto;
        width: 100%;

        .label-table {
          padding-right: 40px;
        }

        .modal-region-select,
        .modal-region-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          cursor: pointer;
        }
      }

      .btn-box {
        padding: 10px 5px;

        .custom-button {
          margin: 0 auto;

          &:hover {
            transform: scale(1.005);
          }
        }
      }
    }

    .content-service {
      width: 50%;
      color: black;
      padding: 13px 0 20px 0;
      height: 100%;

      .checkbox-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 44rem;
        overflow: auto;
      }

      .checkbox-grid-item {
        flex: 0 0 calc(33.33% - 10px);
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 2px 0;
        cursor: pointer;

           &:hover {
             span {
               color: rebeccapurple;
             }
           }
      }
      .all {
        span {
          color: rebeccapurple;
          font-size: 13px;
        }
      }
    }
  }
}