$app-0: #ffdc5a;
$app-1: #edab25;
$app-2: #b67c00;

$secondary-0: #73ff8c;
$secondary-1: #30da5c;
$secondary-2: #00a72c;

$bg-dark-0: #212121;
$bg-dark-1: #303030;
$bg-dark-2: #424242;

// $bg-dark-0: #050708;
// $bg-dark-1: #555555;
// $bg-dark-2: #424242;

$text-0: rgba(255, 255, 255, 1);
$text-1: rgba(255, 255, 255, 0.7);
$text-2: rgba(255, 255, 255, 0.5);

$trip-status-0: #4bd948;
$trip-status-1: #b04aff;
$trip-status-2: #fff500;
$trip-status-3: #00759a;
$trip-status-4: #f5a623;
$trip-status-5: #ff382c;
//$trip-status-5: #39f;
$trip-status-6: #ff00b8;
//$trip-status-6: #ff382c;
$trip-status-7: #cccccc;

/* Light Mode */
$bg-light-0: #e5e5e5;
$bg-light-1: #f6f6f6;
$bg-light-2: #ffffff;
$bg-light-3: #f7f7f7;
$bg-light-4: #eef0f2;
$bg-sidebar-0: #1a1a1a;

$bd-light-0: #e5e5e5;
$bd-light-1: #bdbdbd;
$bd-light-2: #e1e1e1;
$bd-light-3: #ececec;
$bd-light-3: #e6e9ec;
$bd-light-4: #e5e5e5;

$text-light-0: #181918;
$text-light-1: #616161;
$text-light-2: #363636;
$text-light-3: #868696;
$text-light-4: #282828;
$text-light-5: #1c1c1c;
$text-light-6: #333537;
$text-light-7: #ffffff;
$text-light-8: #000000;

$secondary-light-0: #219737;

$bg-btn-0: #ef5816;
$bg-btn-1: #ff382c;
