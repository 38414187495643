@import '../../../scss/variables';

.GrantRoleContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.GrantRole {
  display: flex;
  background: $bg-light-1;
  flex-direction: column;
  height: 100%;
  padding: 6px 10px 0;
  z-index: 8;

  &__wrapper {
    width: 100%;
    height: 100%;
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
}

.GrantRole-Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  h1 {
    font-size: 22px;
    font-weight: 600;
    color: #1c1c1c;
  }
}

.Title {
  background: $bg-light-2;
  border-bottom: 1px solid #e1e1e1;
  .title-text {
    color: $text-light-2;
    border-radius: 5px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0 0 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto", serif;
  }
}

.grant-role-input {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  height: 35px;
  color: black;

  .notranslate {
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.entity-select-box,
.region-select-box,
.map-region-select-box {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  height: 35px;
  color: black;
}