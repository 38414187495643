@import '../../../scss/variables';

.ListAccount {
  &__Wrapper {
    width: 35%;
    border-radius: 5px;
    background: $bg-light-2;
    position: relative;

    .list-box {
      color: #1a1a1a;
      padding: 0.75rem 0.5rem;

      .list-box-table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        .row-shortcut {
          width: 30%;
        }
        tr {

          &:hover {
            color: cornflowerblue;
          }
        }

        th {
          border: 1px solid $trip-status-7;
          background: $bg-light-0;
          height: 30px;
        }

        td {
          border: 1px solid $trip-status-7;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
        }

        .table-row:nth-child(even) {
          background-color: $bg-light-4;
        }
      }

      .pagination {
        display: flex;
        justify-content: left;
        padding: 20px 0;

        button {
          border: none;
          background-color: #f2f2f2;
          color: #333;
          padding: 2px 12px;
          margin: 0 5px;
          font-size: 14px;
          cursor: pointer;
          border-radius: 4px;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #ddd;
            transform: scale(1.05);
          }
        }

        .active {
          background-color: $bg-btn-0;
          color: $bg-light-2;
        }

        .space-more {
          padding: 0 10px;
        }

        .reload-icon {
          padding: 0 10px;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }

          img {
            padding: 3px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__Action {
    border-bottom: 1px solid #e1e1e1;
    padding: 1rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    .search-box {
      width: 50%;
      position: relative;

      .action-search-input {
        position: absolute;
        right: -5px;
        top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon-loading {
          animation: spin 0.8s linear infinite;
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }

        .search-btn {
          width: 24px;
          height: 24px;
          padding: 0 3px;
          margin-right: 5px;

          &:hover {
            transform: scale(1.2);
          }
        }

        .clear-btn {
          width: 24px;
          height: 24px;
          padding: 6px;
          transform: translateX(-8px);

          &:hover {
            transform: translateX(-8px) scale(1.2);
          }
        }
      }
    }
    .select-entity {
      width: 50%;
    }
    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
      img {
        animation: spin 0.8s linear infinite;
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

