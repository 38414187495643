@import '../../../scss//variables';

.UserContainer {
    @import 'Nav';
    @import 'SideBar';

    @import 'WaitingCalls';

    overflow: hidden;

    position: relative;

    .SideBarWidth {
        width: 3rem;
    }

    .AppWidth {
        width: calc(100% - 3rem);
    }

    .MarginWithSideBarOpen {
        // padding-left: percentage(calc(1 / 6));
    }

    .MarginWithNoSideBarOpen {
        // padding-left: 3rem;
    }

    .UserContainerBody {
        //min-height: calc(100vh - 2.5rem);
        height: 100vh;
        position: relative;
    }

    .RightPanelContainer {
        transform: translateX(0);
        //transition: all 0.2s linear;
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: flex-end;
        pointer-events: none;
        z-index: 0;
        //@import 'WaitingCalls';

        transition: transform 0.2s linear;
    }

    .RightPanelContainer.ShowPreviewTrips {
        transform: translateX(40vw);
    }

    .CardSetupBooking {
        position: absolute;
        top: 50;
        left: 180px;
    }
}
